const API_URL: string = process.env.REACT_APP_API_URL || ''

export default class Family {

	static retrieve = async (uuid: string): Promise<any | null> => {
		return fetch(`${API_URL}/family/${uuid}`)
			.then(response => {
				if (!response.ok) {
					return null
				} else {
					return response.json()
				}
			})
	}

	static decline = async (uuid: string): Promise<boolean> => {
		return fetch(`${API_URL}/family/${uuid}`, {
			method: 'DELETE'
		}).then(response => response.ok)
	}

	static accept = async (uuid: string): Promise<boolean> => {
		return fetch(`${API_URL}/family/${uuid}`, {
			method: 'PUT'
		}).then(response => response.ok)
	}
}