import { useEffect, useState } from "react"
import { Member } from "./domain/member"
import Family from "./domain/family"
import Header from "./Header"

const PATH: string = '/join/'
const APPSTORE_LINK: string = 'https://apps.apple.com/es/app/tildone/id6473126292'

const Join = () => {
	const [uuid, setUuid] = useState<string | null>()
	const [members, setMembers] = useState<Member[] | null>()
	const [isRedeemed, setIsRedeemed] = useState<boolean>(false)
	const [handlingResult, setHandlingResult] = useState<string | null>()

	useEffect(() => {
		const retrieveFamily = async () => {
			const url: string = window.location.href
			const uuid: string = url.substring(url.lastIndexOf(PATH) + PATH.length)
			setUuid(uuid)
			try {
				Family.retrieve(uuid)
					.then(invite => {
						if (!invite) {
							setHandlingResult("This family share invitation does not exist")
							return
						}
						setIsRedeemed(!!invite.accepted)
						setMembers(invite.family.members.filter((member: Member) => !!member.givenName))
					})
			} catch {
				setHandlingResult("Failed to retrieve the family details")
			}
		}
		retrieveFamily()
	}, [])

	return <>
		<Header />
		<main>
			<div className="featured">
				{!handlingResult &&
					<h4>
						You have been invited to join a family
					</h4>
				}
				{handlingResult &&
					<h4 className="failed_message">
						{handlingResult}
					</h4>
				}
				{members && members.length === 0 &&
					<p>There are no members in this family yet</p>
				}
				{members && members.length > 0 && <>
					Members: 
					<ul>
						{members.map((member, index) => {
							return (
								<li key={index} style={{ fontWeight: 200 }}>
									{member.givenName}
									{member.isInviteSender &&
										<span className="inviter_tag">Invited you</span>
									}
									{member.isInviteRecipient &&
										<span className="invited_tag">You</span>
									}
								</li>
							)
						})}
					</ul>
				</>}
				{!members && !handlingResult &&
					<div className="spinner" />
				}
				{!isRedeemed && <>
					<p>
						Please <a href={APPSTORE_LINK}>install the app</a>, and once it’s installed, click <a href={`${PATH}${uuid}`}>the invitation link</a> you received again to complete joining the family.
					</p>
					<a href={APPSTORE_LINK} target="_blank" rel="noreferrer">
						<img src="/appstore.svg" alt="App Store" id="app-store" />
					</a>
				</>}
				{isRedeemed &&
					<h4 className="success_message">
						This family share invitation has already been accepted
					</h4>
				}
			</div>
			<a href={APPSTORE_LINK} className="tip">
				What is Family goals and what does it mean to be invited to join a family?
			</a>
		</main>
	</>
}

export default Join