import { useState } from "react"
import Header from "./Header"

const Family = () => {
	const [code, setCode] = useState<string>("")
	return <>
		<Header />
		<main>
			<div className="featured" style={{
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center'
			}}>
				<p>Enter the invitation code to join a family</p>
				<input
					type="text"
					value={code}
					placeholder="Enter the invitation code"
					onChange={e => setCode(e.target.value)}
					style={{ marginBottom: '1rem' }}
				/>
				{code.length > 0 &&
					<a href={`https://family.cuatro.studio/join/${code}`}>{`https://family.cuatro.studio/join/${code}`}</a>
				}
			</div>
		</main>
	</>
}
  
export const FAMILY_APP_NAME: string = 'Family goals'
export const FAMILY_DOMAIN: string = 'family.cuatro.studio'

export default Family